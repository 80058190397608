import React from "react"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import paths from "../constants/paths"
import styled from "styled-components"

const Container = styled.div`
  section {
    padding-top: 1rem;
    padding-bottom: 0.25rem;

    h2, h3 {
      margin-top: 0;
    }
  }

  > .gatsby-image-wrapper {
    max-height: 95vh;
  }
`

const Hero = styled.div`
  width: 100%;
  max-height: calc(85vh - 76px);
  position: relative;

  .heroImg {
    .gatsby-image-wrapper, img {
      max-height: calc(85vh - 76px);
    }
  }
`

const HowToElopeListContainer = styled.div`
  position: relative;

  p {
    word-wrap: break-word;
  }

  > section {
    padding: 0;
    background: var(--brand-color-off-white);
    z-index: 10;
    padding: 1rem;
  }

  h2 {
    margin-top: 0;
    padding-top: 1rem;
  }

  @media (min-width: 480px) {
    p {
      text-align: left;
    }
  }
  
  @media (min-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    
    > section {
      margin: 0 auto;
      border-radius: 25px;
      width: 80vw;
      max-width: 1000px;
      text-align: center;  
    }
  }
`;

const BackgroundImage = styled.div`
  display: none;
  visibility: hidden;

  @media (min-width: 768px) {
    display: block;
    visibility: visible;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;

    .gatsby-image-wrapper, img {
      height: 100vh;
      width: 100vw;
    }
  }
`

const HowToElopeIntro = styled.article`
  background: #fff;
`

const HowToElopePage = ({ location }) => {
    return (
        <Container>
            <Seo title={paths.howToElope.title} location={location} />
            <Hero>
                <StaticImage className="heroImg" alt="seattle-elopement-photographer-snoqualmie-pass-wedding-franklin-falls-adventure-photography-135" src="../images/seattle-elopement-photographer-snoqualmie-pass-wedding-franklin-falls-adventure-photography-135.jpg" layout="fullWidth" />
            </Hero>
            <HowToElopeIntro>
                <h2>How to Elope in Washington</h2>
                <p>Why choose the PNW?</p>
                <p>
                    Washington State has the best scenery, from the rugged coast, to the mossy forest, waterfalls, alpine lakes,
                    and the wildflower filled mountains. Washington is the dream for elopement wedding locations. With three three
                    national parks and a vast amount of wilderness to explore, I'm confident you can find your dream wedding location
                    here.
                </p>
                <p>
                    Whether you're dreaming of saying your vows amidst wildflower meadows at Mount Rainier National Park, or getting
                    married with an intimate group at a cabin in the woods, hiking to panoramic mountain views at North Cascades National
                    Park, chasing waterfalls, or catching the sunset on the Olympic Peninsula, sea to mountain, I can help make your
                    adventure wedding a reality.
                </p>
                <p className="text-center">
                    <Link className="pure-button pure-button-primary full-touch-button" to={paths.contact.url}>Book Now</Link>
                </p>
            </HowToElopeIntro>
            <HowToElopeListContainer>
                <BackgroundImage>
                    {/* <StaticImage alt="Hurricane Ridge Adventure Wedding Olympic National Park Elopement Photographer 4" src="../images/theme/mountains/Hurricane-Ridge-Adventure-Wedding-Olympic-National-Park-Elopement-Photographer-4.jpg" width={1440} /> */}
                    <StaticImage alt="Artist-Point-Elopement-NorthCascades-Washington-Adventure-Wedding-Photographer" src="../images-galleries/portfolios/weddings/Artist-Point-Elopement-NorthCascades-Washington-Adventure-Wedding-Photographer-14.jpg" width={1440} />
                </BackgroundImage>
                <section>
                    <h2>How to Elope with Photography by Betty Elaine:</h2>
                    <h3>1. Phone consultation</h3>
                    <p>
                        I want to hear more about your love story, and desires for your wedding day. I'm here to answer any questions you have about having an adventure wedding, and walk you through the process. By building a relationship with your photographer, you'll become familiar and confident in my style and plan before the big day.
                    </p>
                    <h3>2. Book your date</h3>
                    <p>
                        If you ended the phone consultation and said to each-other “Let's book Betty!”, then I will
                        send you the contract and invoice for booking your date.
                    </p>
                    <h3>3. Location planning, logistics, and legalities</h3>
                    <p>
                        This is the fun part! We make your elopement day a reality. If you're still unsure of where you will elope,
                        I'm here to help you choose. The Pacific Northwest is my home, and as a photographer that loves travel and
                        hiking, I am often a guide to my couples helping them plan the best wedding day they can imagine. I have
                        locations I save to match with my couples, based on what type of experience they want for their elopement.
                    </p>
                    <h3>4. Vendor Referrals</h3>
                    <p>
                        Florals, wedding planners, on location hair & makeup, officiants, whatever you need I will connect you with
                        professionals that will make your elopement day dreams a reality.
                    </p>
                    <h3>5. Itinerary and plan A & B for your adventure elopement</h3>
                    <p>
                        I create the timeline and plan for your elopement day based no the, location, trail, experiences, and vendors
                        planned and booked for your day. I also obsessively track weather, and we will have backup plans in place if needed.
                    </p>
                    <h3>6. Have the best day ever</h3>
                    <p>
                        Hike amidst alpine wildflowers, have a sailboat picnic, kayak across a lake “Just Married”, enjoy a beach campfire
                        at sunset, have your first dance under the stars and a candle dinner at your cabin in the woods, enjoy whatever epic
                        adventure you planned to have. But most importantly, you get to say your wedding vows in a way that is authentically
                        you. You married your best friend!
                    </p>
                    <h3>7. Remember it all with your epic wedding photos</h3>
                    <p>
                        After I document your day, I am meticulous about backing up the files. Once all edits are complete, the images are
                        then delivered to you in an online gallery for you to download, and share with family and friends. Enjoy your images
                        for a lifetime.
                    </p>
                    <p className="text-center">
                        <Link className="pure-button pure-button-primary full-touch-button" to={paths.contact.url}>Let's Chat!</Link>
                    </p>
                </section>
            </HowToElopeListContainer>
        </Container>
    )
}

export default HowToElopePage
